import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layouts/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          requireAuth: false, //
          keepAlive: true,
          title: "首页",
        },
        component: (resolve) => require(["@/views/home/home"], resolve),
      },
      {
        path: "/user",
        name: "user",
        meta: {
          requireAuth: false, //
          title: "我的",
        },
        component: (resolve) => require(["@/views/user/index"], resolve),
      },
      {
        path: "/exchange/index",
        name: "exchange",
        meta: {
          requireAuth: false, //
          title: "兑换",
        },
        component: (resolve) => require(["@/views/exchange/index"], resolve),
      },
      {
        path: "/login/login",
        name: "login",
        meta: {
          requireAuth: false, //
          title: "登录",
        },
        component: (resolve) => require(["@/views/login/login"], resolve),
      },

      {
        path: "/lashin/lashin",
        name: "lashin",
        meta: {
          requireAuth: false, //
          title: "拉新",
        },
        component: (resolve) => require(["@/views/lashin/index"], resolve),
      },
      {
        path: "/user/knapsack/knapsack",
        name: "knapsack",
        meta: {
          requireAuth: false, //
          title: "背包",
        },
        component: (resolve) =>
          require(["@/views/user/knapsack/knapsack"], resolve),
      },
      {
        path: "/news/newsDetails",
        name: "newsDetails",
        meta: {
          requireAuth: false, //
          title: "新闻详情",
        },
        component: (resolve) => require(["@/views/news/newsDetails"], resolve),
      },
      {
        path: "/journalism/journalism",
        name: "journalism",
        meta: {
          requireAuth: false, //
          title: "新闻",
        },
        component: (resolve) => require(["@/views/journalism/journalism"], resolve),
      },
      {
        path: "/home/commodityDetails/commodityDetails",
        name: "commodityDetails",
        meta: {
          requireAuth: false, //
          title: "商品详情",
        },
        component: (resolve) => require(["@/views/home/commodityDetails/commodityDetails"], resolve),
      },
      {
        path: "/commodity/index",
        name: "commodity",
        meta: {
          requireAuth: false, //
          title: "商品",
        },
        component: (resolve) => require(["@/views/commodity/index"], resolve),
      },
      {
        path: "/gameText/modeIntroduction",
        name: "modeIntroduction",
        meta: {
          requireAuth: false, //
          title: "模式介绍",
        },
        component: (resolve) => require(["@/views/gameText/modeIntroduction"], resolve),
      },
      {
        path: "/gameText/gameplayIntroduction",
        name: "gameplayIntroduction",
        meta: {
          requireAuth: false, //
          title: "玩法介绍",
        },
        component: (resolve) => require(["@/views/gameText/gameplayIntroduction"], resolve),
      },
      {
        path: "/gameText/propIntroduction",
        name: "propIntroduction",
        meta: {
          requireAuth: false, //
          title: "道具介绍",
        },
        component: (resolve) => require(["@/views/gameText/propIntroduction"], resolve),
      },
      {
        path: "/gameText/roleIntroduction",
        name: "roleIntroduction",
        meta: {
          requireAuth: false, //
          title: "角色介绍",
        },
        component: (resolve) => require(["@/views/gameText/roleIntroduction"], resolve),
      },
      {
        path: "/gameText/whitePaper",
        name: "whitePaper",
        meta: {
          requireAuth: false, //
          title: "白皮书",
        },
        component: (resolve) => require(["@/views/gameText/whitePaper"], resolve),
      },
      {
        path: "/gameText/bubbleProject",
        name: "bubbleProject",
        meta: {
          requireAuth: false, //
          title: "泡泡计划",
        },
        component: (resolve) => require(["@/views/gameText/bubbleProject"], resolve),
      },
      {
        path: "/gameText/ethnic",
        name: "ethnic",
        meta: {
          requireAuth: false, //
          title: "种族",
        },
        component: (resolve) => require(["@/views/gameText/ethnic"], resolve),
      },
      {
        path: "/gameText/talent",
        name: "talent",
        meta: {
          requireAuth: false, //
          title: "天赋",
        },
        component: (resolve) => require(["@/views/gameText/talent"], resolve),
      },
      {
        path: "/gameText/gameFunction",
        name: "gameFunction",
        meta: {
          requireAuth: false, //
          title: "游戏功能",
        },
        component: (resolve) => require(["@/views/gameText/gameFunction"], resolve),
      },
      {
        path: "/gameText/crazies",
        name: "crazies",
        meta: {
          requireAuth: false, //
          title: "疯狂派",
        },
        component: (resolve) => require(["@/views/gameText/crazies"], resolve),
      },
      {
        path: "/gameText/beta1",
        name: "beta1",
        meta: {
          requireAuth: false, //
          title: "Beta 1",
        },
        component: (resolve) => require(["@/views/gameText/beta1"], resolve),
      },
      {
        path: "/gameText/arena",
        name: "arena",
        meta: {
          requireAuth: false, //
          title: "竞技场",
        },
        component: (resolve) => require(["@/views/gameText/arena"], resolve),
      },
      {
        path: "/gameText/missionsystem",
        name: "missionsystem",
        meta: {
          requireAuth: false, //
          title: "任务系统",
        },
        component: (resolve) => require(["@/views/gameText/missionsystem"], resolve),
      },
      {
        path: "/bottom/contactus",
        name: "contactus",
        meta: {
          requireAuth: false, //
          title: "联系我们",
        },
        component: (resolve) => require(["@/views/bottom/contactus"], resolve),
      },
      {
        path: "/bottom/problemFeedback",
        name: "problemFeedback",
        meta: {
          requireAuth: false, //
          title: "问题反馈",
        },
        component: (resolve) => require(["@/views/bottom/problemFeedback"], resolve),
      },
      {
        path: "/bottom/recruit",
        name: "recruit",
        meta: {
          requireAuth: false, //
          title: "招聘",
        },
        component: (resolve) => require(["@/views/bottom/recruit"], resolve),
      },
      {
        path: "/login/userAgreement",
        name: "userAgreement",
        meta: {
          requireAuth: false, //
          title: "用户协议",
        },
        component: (resolve) => require(["@/views/login/userAgreement"], resolve),
      },
      {
        path: "/login/privacyAgreement",
        name: "privacyAgreement",
        meta: {
          requireAuth: false, //
          title: "隐私协议",
        },
        component: (resolve) => require(["@/views/login/privacyAgreement"], resolve),
      },
      {
        path: "/home/gamedownload",
        name: "gamedownload",
        meta: {
          requireAuth: false, //
          title: "游戏下载",
        },
        component: (resolve) => require(["@/views/home/gamedownload"], resolve),
      },
      {
        path: "/home/assignment",
        name: "assignment",
        meta: {
          requireAuth: false, //
          title: "任务",
        },
        component: (resolve) => require(["@/views/home/assignment"], resolve),
      },
      {
        path: "/gameRelated/gameIntroduction",
        name: "gameIntroduction",
        meta: {
          requireAuth: false, //
          title: "游戏介绍",
        },
        component: (resolve) => require(["@/views/gameRelated/gameIntroduction"], resolve),
      },
      {
        path: "/gameRelated/bubbleProject",
        name: "bubbleProject",
        meta: {
          requireAuth: false, //
          title: "泡泡计划",
        },
        component: (resolve) => require(["@/views/gameRelated/bubbleProject"], resolve),
      },
      {
        path: "/dailyattendance/dailyattendance",
        name: "dailyattendance",
        meta: {
          requireAuth: false, //
          title: "抽奖",
        },
        component: (resolve) => require(["@/views/dailyattendance/dailyattendance"], resolve),
      },
      {
        path: "/marketplace",
        name: "marketplace",
        meta: {
          requireAuth: false, //
          title: "市场",
        },
        component: (resolve) => require(["@/views/marketplace/index"], resolve),
      },
      {
        path: "/marketplace/my/index",
        name: "individualAccount",
        meta: {
          requireAuth: false, //
          title: "个人账户",
        },
        component: (resolve) => require(["@/views/marketplace/my/index"], resolve),
      },
      {
        path: "/marketplace/chipCrystal/chipCrystal",
        name: "chipCrystal",
        meta: {
          requireAuth: false, //
          title: "芯片晶体",
        },
        component: (resolve) => require(["@/views/marketplace/chipCrystal/chipCrystal"], resolve),
      },
      {
        path: "/marketplace/chipCrystal/bazaar",
        name: "bazaar",
        meta: {
          requireAuth: false, //
          title: "市场",
        },
        component: (resolve) => require(["@/views/marketplace/chipCrystal/bazaar"], resolve),
      },
      {
        path: "/marketplace/property/property",
        name: "property",
        meta: {
          requireAuth: false, //
          title: "道具",
        },
        component: (resolve) => require(["@/views/marketplace/property/property"], resolve),
      },
      {
        path: "/marketplace/property/propertyTpye",
        name: "propertyTpye",
        meta: {
          requireAuth: false, //
          title: "道具",
        },
        component: (resolve) => require(["@/views/marketplace/property/propertyTpye"], resolve),
      },
      {
        path: "/marketplace/property/bazaar",
        name: "propertyTpye",
        meta: {
          requireAuth: false, //
          title: "市场",
        },
        component: (resolve) => require(["@/views/marketplace/property/bazaar"], resolve),
      },
      {
        path: "/marketplace/skin/skin",
        name: "skin",
        meta: {
          requireAuth: false, //
          title: "皮肤",
        },
        component: (resolve) => require(["@/views/marketplace/skin/skin"], resolve),
      },
      {
        path: "/marketplace/skin/bazaar",
        name: "bazaar",
        meta: {
          requireAuth: false, //
          title: "市场",
        },
        component: (resolve) => require(["@/views/marketplace/skin/bazaar"], resolve),
      },
      {
        path: "/home/activity",
        name: "bazaar",
        meta: {
          requireAuth: false, //
          title: "活动",
        },
        component: (resolve) => require(["@/views/home/activity"], resolve),
      },
      {
        path: "/activity/index",
        name: "activity",
        meta: {
          requireAuth: false, //
          title: "活动",
        },
        component: (resolve) => require(["@/views/activity/index"], resolve),
      },
      {
        path: "/activity/myInvitation",
        name: "myInvitation",
        meta: {
          requireAuth: false, //
          title: "邀请码",
        },
        component: (resolve) => require(["@/views/activity/myInvitation"], resolve),
      },
      {
        path: "/activity/interpreter/index",
        name: "interpreter",
        meta: {
          requireAuth: false, //
          title: "转换机",
        },
        component: (resolve) => require(["@/views/activity/interpreter/index"], resolve),
      },
    ],
  },

];

const router = new VueRouter({
  mode: "hash",
  routes,
  base: "",
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  next();
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
