// 全局混入
import Vue from "vue";

import { mapState, mapMutations } from "vuex";

import { reg } from "@/utils/validate";
Vue.mixin({
  data() {
    return {
      reg,
    };
  },
  computed: {
    ...mapState(["username", "property", "suerInfo", "tickets"]),
  },
  methods: {
    ...mapMutations(["usernameUser", "userProperty", "userSuerInfo", "ticketsInfo"]),
  },
});
