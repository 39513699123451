<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {},
  mounted() {
    this.getLast();
  },
  methods: {
    async getLast() {
      const { data, code } = await this.$ajax.getLast();
      let versionNumber = localStorage.getItem("versionNumber");
      if (data.versionNumber != versionNumber) {
        window.location.reload();
        localStorage.setItem("versionNumber", data.versionNumber);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

@import "./common.scss";
@import "./styles/font/fonts/stylesheet.css";
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
